import { fetchAPI } from "@/lib/api";
import { responsiveImageFragment, pageRelatedFragment } from "@/lib/fragments";
import { getSiteId } from "./site-config";

import {
	HeroSliderFragment,
	TextImageFragment,
	ImageVideoFragment,
	SectionGalleryFragment,
	TextGalleryFragment,
	SectionBrandRecipeFragment,
} from "@/lib/section-fragment";

export async function getHomePage(locale, preview) {
	return getSiteId().then((website) => {
		const data = fetchAPI(
			`
      query HomePage($locale: SiteLocale = ${process.env.DEFAULT_SITE_LOCALE}) {
        brandsHomepage(filter: {siteConfig: {eq: "${website.id}"}}, locale: $locale) {
          __typename
          id
          hero{
            __typename
            ...HeroSliderFragment
          }
          sections{
            __typename
            ...TextImageFragment
            ...ImageVideoFragment
          }
        }
      }
      ${HeroSliderFragment}
      ${responsiveImageFragment}
      ${TextImageFragment}
      ${ImageVideoFragment}
      

      `,
			{
				preview,
				variables: {
					locale,
				},
			}
		);

		return data;
	});
}

export async function getSkuBySlug(slug, locale, preview) {
	const data = fetchAPI(
		`
        query GetAvailableSku($locale: SiteLocale = ${process.env.DEFAULT_SITE_LOCALE}) {
            availableSku(filter: {slug: {eq: "${slug}"}}, locale: $locale) {
            __typename
            _allSlugLocales {
              locale
              value
            }
            _seoMetaTags {
              content
              attributes
              tag
            }
            id
            h3
            skuCode
            skuName

            skuImageGallery {
              id
              responsiveImage (imgixParams: { fit: crop, h: 600, crop: focalpoint, auto:format, q:70}) {
                ... responsiveImageFragment
              }
            }
          }
        }
        ${responsiveImageFragment}
        `,
		{
			preview,
			variables: {
				locale,
				slug,
			},
		}
	);
	return data;
}

export async function getProduct(slug, locale, preview) {
	const data = fetchAPI(
		`
        query GetMainProduct($locale: SiteLocale = ${process.env.DEFAULT_SITE_LOCALE}) {
            product(filter: {slug: {eq: "${slug}"}}, locale: $locale) {
            __typename
            _allSlugLocales {
              locale
              value
            }
            _seoMetaTags {
              content
              attributes
              tag
            }
            id
            harvestYear
            outsystemVersionId
            h3SapCode
            productBrand {
              id
              title
              image {
                typeofimage
                image {
                  responsiveImage (imgixParams: { fit: crop, w: 1450, crop: focalpoint, auto:format, q:70}) {
                    ... responsiveImageFragment
                  }
                }
              }
            }
            productDescription
            productDescriptionShort
            
            tasting
            vintageYear
            technicalSheet {
              id
              _updatedAt
              url
            }
            acidity {
              id
              scale
            }
            albumin
            alcohol
            alcoholContentByVolume
            alcoholContentByVolume20
            alcoholContentByVolume20Specification
            alcoholContentByVolume20Tolerance
            alcoholFor30ml
            alcoholFor60ml
            aromaIntensity {
              scale
              id
            }
            arsenic
     
        
            body {
              scale
            }
            bottler {
              name
              location {
                name
              }
              excerpt
            }
            bottlingDate
            cadmium
            calcium
            calciumSpecification
            carbohydrates
            carbonDioxide
            carbonDioxideSpecification
            casein
      
            containsSulfites
            copper
            copperSpecification
            energyValueKcal
            energyValueKcalFor30ml
            energyValueKcalFor60ml
            energyValueKj
            energyValueKjFor30ml
            energyValueKjFor60ml
            enjoy
            ethylCarbamate
            fat
            finish {
              id
              scale
            }
            flavourIntensity {
              scale
            }
            freeSulphurDioxide
            freeSulphurDioxideSpecification
            freeSulphurDioxideTolerance
      
            grapevariety {
              grapeVarietyQuantity
              grapeVarietyVariety {
                title
                description
              }
            }
      
            integratedProduction
            iron
            ironSpecification
            lastTasted
            lead
            maturation
            methanol
            ochratoxinA
            overpressureAt20C
            overpressureAt20CSpecification
            ph
            phSpecification
            phTolerance
            productName
            productCountry {
              title
            }
            productOrigin {
              originDesignation
            }
            productRegion {
              name
            }
            productTypeGeneric {
              name
            }
            productTypeSpecific {
              name
              _allSlugLocales {
                locale
                value
              }
            }
            productColour {
              scale
            }
            productCategory {
              style
            }
            producedBottles
            protein
            salt
            saturatedFattyAcids
            factSheetImage {
              responsiveImage (imgixParams: { fit: crop, h: 700, crop: focalpoint, auto:format, q:70}) {
                ... responsiveImageFragment
              }
            }
            sku {
              slug
              skuName
              skuCode
              buyNow {
                url
                label {
                  label
                }
              }
              skuImageGallery {
                responsiveImage (imgixParams: { fit: crop, h: 700, crop: focalpoint, auto:format, q:70}) {
                  ... responsiveImageFragment
                }
              }
            }
            slug
            serve
            store
            sugarFreeExtract
            sugarFreeExtractSpecification
            sugars
            sugarsFor30ml
            sugarsFor60ml
            veganCertified
            suitableForVegan
            suitableForVegetarian
     
            sustainableViticulture
            sweetness {
              scale
            }
            tannin {
              scale
            }
            technicalSheet {
              url
              _updatedAt
              filename
            }
            texture {
              scale
            }

            totalAcidityTartaricAcid
            totalAcidityTartaricAcidSpecification
            totalAcidityTartaricAcidTolerance
            totalSugars
            totalSugarsSpecification
            totalSugarsTolerance
            totalSulphurDioxide
            totalSulphurDioxideSpecification
            totalSulphurDioxideTolerance
            turbidity
            turbiditySpecification
            volatileAcidityVolatileAcid
            volatileAcidityVolatileAcidSpecification
            volatileAcidityVolatileAcidTolerance
            wineListProductName
            winemakerBlender {
              company
              founder
              masterBlender
              taster
              title
              excerpt
              description
            }
            winemaking
            zinc
          }
        }
        ${responsiveImageFragment}
        `,
		{
			preview,
			variables: {
				locale,
				slug,
			},
		}
	);
	return data;
}

export async function getPortfolioDetails(
	locale,
	preview,
	siteConfig,
	portfolioSlug
) {
	const data = await fetchAPI(
		`
        query GetPortfolioDetails($locale: SiteLocale = ${process.env.DEFAULT_SITE_LOCALE}) {
          brandsProduct(filter: {slug: {eq: "${portfolioSlug}"}, siteConfig: {eq: "${siteConfig}"}}, locale: $locale) {
            __typename
            slug
            showMediaPublicationLogo
            accoladesShowAwards
            accoladesShowRatings
            accoladesAwardsType {
              __typename
              id
              title
            }
            accoladesRatings100 {
              __typename
              id
              ratingValue
            }
            accoladesRatings20 {
              __typename
              id
              ratingValue
            }
            accoladesSlug
            awardsYear
            showRelatedSection
            relatedSectionPosition
            bgColor{
              color{
                hex
              }
            }
     
            relatedSections {
              __typename
              ...TextImageFragment
              ...ImageVideoFragment
              ...SectionGalleryFragment
              ...SectionBrandRecipeFragment

            }
            productsList {
              id
              productsDefinition {
                hideTechnicalSheet
                hide
                color {
                  color {
                    hex
                  }
                }
                extraFieldAdd
                extraField {
                  text
                }
                overwriteDefaultData
                hideDescription
                swapProductTitleWithLogo
                logoLabel
                logoAsset {
                  url
                  alt
                }
                addLogoToProduct
                companionAsset{
                  url
                  alt
                }
                customData {
                  id
                  typeofcontent
                  content {
                    __typename
                    ... on SectionTextRecord {
                      text
                    }
                    ... on SectionImageRecord {
                      ...ImageVideoFragment
                    }
                  }
                }
        
                addProductGallery
                hideGalleryLabel
                gallery {
                  id
                  focalPoint {
                  y
                  x
                  }
                  responsiveImage {
                    ... responsiveImageFragment
                  }
                }
                highlightColor{
                  color{
                    hex
                    red
                    green
                    blue
                  }
                }
                geo {
                  countryAvailability
                  listOfCountries
                  countries {
                    countryCode
                  }
                }
                product {
                  id
                  productName
                  slug
                  productTypeSpecific {
                    slug
                    _allSlugLocales {
                      locale
                      value
                    }
                  }
                  factSheetImage {
                    responsiveImage (imgixParams: { fit: crop, h: 700, crop: focalpoint, auto:format, q:70}) {
                      ... responsiveImageFragment
                    }
                  }
                  sku {
                    slug
                    skuName
                    skuImageGallery {
                      id
                      responsiveImage {
                       ... responsiveImageFragment
                      }
                    }
                  }
                }
                relatedProducts {
                  id
                  __typename
                  productName
                  slug
                  factSheetImage {
                    responsiveImage (imgixParams: { fit: crop, h: 700, crop: focalpoint, auto:format, q:70}) {
                      ... responsiveImageFragment
                    }
                  }
                  sku {
                    slug
                    skuName
                    skuImageGallery {
                      responsiveImage (imgixParams: { fit: crop, h: 700, crop: focalpoint, auto:format, q:70}) {
                        ... responsiveImageFragment
                      }
                    }
                  }
                  productTypeSpecific {
                    slug
                  }
                }
                label
                media {
                  image {
                    responsiveImage {
                   ... responsiveImageFragment
                    }
                  }
                  typeofimage
                }
                showCertificates
              }
            }
            productsCollection {
              ... on PortfolioCollectionRecord {
                __typename
                title {
                  __typename
                  id
                  slug
                } 
                hideHome
                swapProductsForDefinitions
                productsDefinitionCollection {
                hideTechnicalSheet
                hide
                color {
                  color {
                    hex
                  }
                }
                extraFieldAdd
                extraField {
                  text
                }
                overwriteDefaultData
                hideDescription
                swapProductTitleWithLogo
                logoLabel
                logoAsset {
                  url
                  alt
                }
                addLogoToProduct
                companionAsset{
                  url
                  alt
                }
                customData {
                  id
                  typeofcontent
                  content {
                    __typename
                    ... on SectionTextRecord {
                      text
                    }
                    ... on SectionImageRecord {
                      ...ImageVideoFragment
                    }
                  }
                }
        
                addProductGallery
                hideGalleryLabel
                gallery {
                  id
                  responsiveImage {
                    ... responsiveImageFragment
                  }
                }
                highlightColor{
                  color{
                    hex
                    red
                    green
                    blue
                  }
                }
                geo {
                  countryAvailability
                  listOfCountries
                  countries {
                    countryCode
                  }
                }
                product {
                __typename
                  id
                  productName
                  slug
                  productTypeSpecific {
                    slug
                    _allSlugLocales {
                      locale
                      value
                    }
                  }
                  factSheetImage {
                    responsiveImage (imgixParams: { fit: crop, h: 700, crop: focalpoint, auto:format, q:70}) {
                      ... responsiveImageFragment
                    }
                  }
                  sku {
                    slug
                    skuName
                    skuImageGallery {
                      id
                      responsiveImage {
                       ... responsiveImageFragment
                      }
                    }
                  }
                }
                relatedProducts {
                  id
                  __typename
                  productName
                  slug
                  factSheetImage {
                    responsiveImage (imgixParams: { fit: crop, h: 700, crop: focalpoint, auto:format, q:70}) {
                      ... responsiveImageFragment
                    }
                  }
                  sku {
                    slug
                    skuName
                    skuImageGallery {
                      responsiveImage (imgixParams: { fit: crop, h: 700, crop: focalpoint, auto:format, q:70}) {
                        ... responsiveImageFragment
                      }
                    }
                  }
                  productTypeSpecific {
                    slug
                  }
                }
                label
                media {
                  image {
                    responsiveImage {
                   ... responsiveImageFragment
                    }
                  }
                  typeofimage
                }
                showCertificates

                }
                productsCollection {
                  __typename
                  id
                  productName
                  slug
                  productTypeSpecific {
                    slug
                    _allSlugLocales {
                      locale
                      value
                    }
                  }
                  factSheetImage {
                    responsiveImage (imgixParams: { fit: crop, h: 700, crop: focalpoint, auto:format, q:70}) {
                      ... responsiveImageFragment
                    }
                  }
                    sku {
                  skuImageGallery {
                  responsiveImage {
                    ... responsiveImageFragment
                     }
                     }
                    }
                  }
                }
              }
            ctaGeolocation {
              countryAvailability
              listOfCountries
              countries {
                countryCode
              }
            }
            showBrand
            showDescription
            showHarvestYear
            showImage
            showNavbar
            showNutritionalInformation
            showProductSpecificNutritionalInformation
            showEnergeticInformation
            showWinemaking
            showMaturation
            showStore
            showServe
            showEnjoy
            showWineMaker
            showRelated
            relatedTitleLabel {
              label
            }
            relatedProductsDependOnGeolocation
              geo {
                countryAvailability
                listOfCountries
                countries {
                  countryCode
                }
                products {
                  id
                  productName
                  slug
                  productTypeSpecific {
                    slug
                    _allSlugLocales {
                      locale
                      value
                    }
                  }
                  factSheetImage {
                    responsiveImage (imgixParams: { fit: crop, h: 700, crop: focalpoint, auto:format, q:70}) {
                      ... responsiveImageFragment
                    }
                  }
                  sku {
                    slug
                    skuName
                    skuImageGallery {
                      id
                      responsiveImage {
                       ... responsiveImageFragment
                      }
                    }
                  }
                }
              }
            showBottlesProduced
 
            nutritionalTableLayout
            nutritionalTableBackground {
              color {
                blue
                green
                red
                hex
              }
            }
            relatedTitleColor{
              color{
                hex
              }
            }
            relatedTextColor{
              color{
                hex
              }
            }
            relatedCtaLabelColor{
              color{
                hex
              }
            }
            relatedCtaTypeColor{
              color{
                hex
              }
            }
            relatedBackgroundColor{
              color{
                hex
              }
            }
            relatedCtaType
            relatedCtaLabel{
              label
            }
            relatedCtaIcon{
              img{
                url
              }
            }
            relatedBackgroundImage{
                url
              responsiveImage {
                ... responsiveImageFragment
               }
            }
            relatedProductsSliderLayout
            relatedLayoutReference{
              ...TextGalleryFragment 
            }
            addRelatedTitleUnderline
            addTitleUnderline
            underlineColor{
              color{
              hex
              }
            }
            customUnderlineAsset{
              img{
                url
              }
            }
            addCustomNavigationMarkers
            navigationMarkers{
              img{
                url
              }
            }
            showTasting
            showTechnicalDetails
            showType
            volume
            layoutDetail
            showUmbrellaBrand
            showRelatedDropdownMenu
            backgroundDropdownColor{
              color{
                hex
              }
            }
            iconDropdownColor{
              color{
                hex
              }
            }
            follow
            addShadowOverlay
            overlayShadowColor{
              color{
                hex
              }
            }
            productDetailButtonConfig{
              ...pageRelatedFragment
            }
            buyButtonIcon {
              img {
                url
              }
            }
            technicalSheetButtonIcon {
              img {
                url
              }
            }
            veganCertificateButtonIcon {
              url
            }
            productDetailTitlesColor{
              color{
                hex
              }
            }
            productDetailTextColor {
              color {
                hex
              }
            }
    
            mobileProductAlignment
            mobileProductDetailColor{
              color{
                hex
              }
            }
            heroTextColor{
              color{
                hex
              }
            }
            detailBgColor{
              color{
                hex
              }
            }
            bgColor{
              color{
                hex
              }
            }
          }
        }
        ${responsiveImageFragment}
        ${TextImageFragment}
        ${ImageVideoFragment}
        ${SectionGalleryFragment}
        ${TextGalleryFragment}
        ${pageRelatedFragment}
        ${SectionBrandRecipeFragment}
    `,
		{
			preview,
			variables: {
				locale,
			},
		}
	);
	return data;
}
export async function getProductIdBySlug(locale, preview, slug) {
	const data = await fetchAPI(
		`
        query GetProductIdBySlug($locale: SiteLocale = ${process.env.DEFAULT_SITE_LOCALE}) {
          product(filter: {slug: {eq: "${slug}"}}, locale: $locale) {
            id
            __typename
            _allSlugLocales {
              locale
              value
            }
          }
        }
    `,
		{
			preview,
			variables: {
				locale,
			},
		}
	);
	return data;
}
