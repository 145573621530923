import React from "react";
import { Paragraph } from "@/atoms/Paragraph";
import { FullBackgroundImage } from "@/components/Molecules/ImageCard";
import { OverlayShadow } from "@/components/Molecules/OverlayShadow/Overlay";
import { Title } from "@/components/Atoms/Title";
import { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ClosePop, PlayIcon } from "@/components/Atoms/Vectors";

// pagination or load more btn

export default function VideoGallery(data: any) {
  let [isOpen, setIsOpen] = useState(9999999);
  const [playing, setPlaying] = useState(false);

  function closeModal() {
    setIsOpen(9999999);
  }

  function showModal(i: number) {
    setIsOpen(i);
  }
  return (
    <>
      <div
        style={{ backgroundColor: data?.bgColor?.color?.hex }}
        className={` ${
          data.useSectionMargins
            ? "py-6 md:pb-6 md:pt-24 lg:pt-32 lg:pb-12"
            : "py-0 pb-12"
        }  relative w-full`}
      >
        <div className="  px-6 lg:px-4 xl:px-2 2xl:px-0 container mx-auto grid grid-cols-4 gap-6">
          {data?.videoGallery.map((galleryConf: any, i: number) => {
            return (
              <div
                className={`${
                  i == 0 ? "col-span-4" : "col-span-2 lg:col-span-1"
                } bg-slate-200 h-[250px] lg:h-[435px]  relative group overflow-hidden`}
                key={i}
              >
                <button
                  className="w-full h-full"
                  type="button"
                  onClick={() => showModal(i)}
                >
                  <article className="relative z-10 p-8 h-full">
                    {" "}
                    <Title
                      tag={`${i == 0 ? "h4" : "h6"}`}
                      data={galleryConf?.title}
                      color={data.textColor?.color}
                    />
                    <div className="absolute right-8 bottom-8">
                      <PlayIcon color={data.textColor?.color?.hex} />
                    </div>
                  </article>
                </button>

                <OverlayShadow
                  data={data.shadowOverlay}
                  layout={data.textAlignment}
                  color={data.shadowOverlayBaseColor}
                  format={data.textAlignment}
                />
                <div className="group-hover:scale-[1.1] absolute top-0 left-0 w-full h-[435px] transition-all duration-1000">
                  <FullBackgroundImage
                    data={
                      galleryConf?.placeholder ||
                      galleryConf?.video?.video?.thumbnailUrl
                    }
                  />
                </div>
                <Transition appear show={isOpen == i} as={Fragment}>
                  <Dialog
                    as="div"
                    className="relative z-[9999999]"
                    onClose={closeModal}
                  >
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-black bg-opacity-75" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                      <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <Dialog.Panel className="w-[95vw] h-[95vh] max-w-[1200px] transform overflow-hidden  text-left align-middle shadow-xl transition-all">
                            <video
                              playsInline
                              style={{ backgroundColor: data.bgColor?.hex }}
                              loop={false}
                              autoPlay={true}
                              muted={false}
                              controls={true}
                              src={galleryConf?.video.video?.mp4Url}
                              onEnded={() => setPlaying(false)}
                              className={` w-full cursor-pointer max-h-[75%] bg-black m-auto `}
                              poster={galleryConf?.placeholder}
                            />
                            <article
                              style={{
                                backgroundColor: data?.popupBgColor?.color?.hex,
                              }}
                              className="p-12 grid lg:grid-cols-3 gap-2 lg:gap-24"
                            >
                              <div className="col-span-1">
                                <Title
                                  tag={`h4`}
                                  data={galleryConf?.title}
                                  color={data.popupTitleColor?.color}
                                />
                              </div>
                              <div className="col-span-2">
                                <Paragraph
                                  copy={galleryConf?.description}
                                  color={data.popupTextColor?.color}
                                ></Paragraph>
                              </div>

                              <div className="absolute top-4 right-4">
                                <button type="button" onClick={closeModal}>
                                  <ClosePop
                                    color={data.textColor?.color?.hex}
                                  />
                                </button>
                              </div>
                            </article>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export const fragment = `
    fragment SectionGalleryVideoFragment on SectionGalleryVideoRecord {
        __typename
      id
      name
      layout 
      useSectionMargins
      bgColor{
        color{
          hex
        }
      }
        textColor{
          color{
            hex
          }
        }
        popupBgColor {
          color {
            hex
          }
        }
        popupTitleColor {
          color {
            hex
          }
        }
        popupTextColor {
          color {
            hex
          }
        }
        shadowOverlay
        shadowOverlayBaseColor {
          hex
        }
        textAlignment
      videoGallery {
        __typename
        title
        description
        usePlaceholder
        placeholder {
          smartTags
          responsiveImage {
            ...responsiveImageFragment
          }
        }
        video {
          id
    
          video {
            mp4Url
            thumbnailUrl
          }
        }
      }
    }
`;
