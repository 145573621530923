/* eslint-disable jsx-a11y/alt-text */

import { ButtonMaster } from "@/components/Atoms/Buttons";
import CustomLink from "@/components/Atoms/CustomLink";
import Image from "@/components/Atoms/Image";
import { FeaturedLabel, ImageLabel } from "@/components/Atoms/Paragraph";
import { BolderTitle, Title } from "@/components/Atoms/Title";
import { slideUp } from "@/lib/functions";
import { routerHandler } from "@/lib/routes/router-links-handler";
import { LazyMotion, domAnimation, m } from "framer-motion";
import { Paragraph } from "@/components/Atoms/Paragraph";
import { Shape } from "@/components/Atoms/Spacer";
import { CustomUnderline, Underline } from "@/components/Atoms/Underline";
import styles from "./index.module.scss";
import { useTranslation } from "next-i18next";
import GeoWrapper from "@/components/Templates/Main/GeoConditional";
import { FullBackgroundImage } from "../ImageCard";

export function ProductCard(props: any) {
  return (
    <>
      <div className="col-span-1">
        {props.data.media.map((media: any) => (
          <div
            key={media.id}
            className="flex items-center mx-auto justify-center "
          >
            <Image className={`w-80`} data={media.image} />
          </div>
        ))}
      </div>
      {props.data.description && (
        <Paragraph
          className="mt-12 text-sm"
          copy={props.data.description}
          color={undefined}
        />
      )}
    </>
  );
}

interface ProductList {
  portfolioSlug: string;
  locale: any;
  data: any;
  text: any;
  style: any;
  btn: any;
  btnStyle: any;
  type: string;
}

export function ProductDefinitionList({
  portfolioSlug,
  locale,
  text,
  data,
  style,
  btnStyle,
  type,
  btn,
}: ProductList) {
  return (
    <div className=" xs:col-span-2 col-span-1 flex-none lg:flex-1 text-center flex flex-col  gap-2 relative z-[1] translate-y-[-15%] group">
      <div className="relative z-1">
        <CustomLink
          href={`${routerHandler({
            typename: data.product?.__typename,
            slug: [
              portfolioSlug,
              data.product.productTypeSpecific.slug,
              data.product?.slug,
            ],
            locale: locale,
          })}`}
        >
          <Image
            data={
              data.product?.factSheetImage ||
              data.product?.sku[0]?.skuImageGallery?.[0]
            }
            className="max-w-[12rem] mx-auto group-hover:translate-y-[-5%] transition-all ease-in-out duration-300"
          />
          <div className=" mx-auto px-4  flex flex-col justify-center items-center">
            <FeaturedLabel
              copy={data.product.productName}
              color={text?.color}
            ></FeaturedLabel>
          </div>
        </CustomLink>
      </div>
      {type === "default" ? (
        <ButtonMaster
          anim={"slideTop"}
          type={"round"}
          cta={btnStyle?.color}
          color={btn?.color}
          link={`${routerHandler({
            typename: data.product?.__typename,
            slug: [
              portfolioSlug,
              data.product.productTypeSpecific.slug,
              data.product?.slug,
            ],
            locale: locale,
          })}`}
        >
          ver mais
        </ButtonMaster>
      ) : (
        <span
          style={{
            backgroundColor: style?.color.hex,
          }}
          className="absolute z-[-1] h-[65%] top-0 w-[12rem] left-0 right-0  m-auto  translate-y-[75%] rounded-t-[25%] rounded-b-[45%]"
        >
          <span
            style={{
              backgroundColor: style?.color.hex,
            }}
            className="absolute z-[-1] h-[12rem] top-0 w-[12rem] left-0 right-0  m-auto   translate-y-[-20%]  rounded-b-[45%]  rounded-t-[45%]"
          ></span>
          <div className="absolute -bottom-4 left-0 right-0 m-auto w-fit group-hover:scale-110 transition-all ease-in-out duration-300">
            <ButtonMaster
              anim={"slideTop"}
              type={"round"}
              cta={btnStyle?.color}
              color={btn?.color}
              link={`${routerHandler({
                typename: data.product?.__typename,
                slug: [
                  portfolioSlug,
                  data.product.productTypeSpecific.slug,
                  data.product?.slug,
                ],
                locale: locale,
              })}`}
            >
              +
            </ButtonMaster>
          </div>
        </span>
      )}
    </div>
  );
}

interface ZigZag {
  awards: any;
  productsDefinition: any;
  portfolioSlug: any;
  locale: any;
  parallax: any;
  text: any;
  order: boolean;
  bgImage: any;
  column: any;
  reduce: boolean;
  type?: string;
  showTypeGeneric: boolean;
  underline: any;
  featuredSize: string;
  typeOfNavigation: boolean;
  productImageOrder: boolean;
  description: boolean;
  buttonConfig: any;
}

export function ZigZagList({
  productsDefinition,
  portfolioSlug,
  locale,
  parallax,
  text,
  order,
  bgImage,
  column,
  reduce,
  type,
  showTypeGeneric,
  productImageOrder,
  underline,
  featuredSize,
  typeOfNavigation,
  description,
  buttonConfig,
}: ZigZag) {
  const { t } = useTranslation();
  return (
    <>
      {productsDefinition?.map((definition: any, index: number) => {
        const style = {
          "--bg": definition?.color?.color?.hex,
          "--highlight": definition?.highlightColor?.color.hex,
        } as React.CSSProperties;
        const featureImage = definition.media?.filter(
          (image: any) => image.typeofimage === "image"
        )?.[0]?.image;

        const bgElement = definition.media?.filter(
          (image: any) => image.typeofimage === "bg"
        )?.[0]?.image;

        const isLeft = order ? index % 2 == 0 : index % 2 !== 0;

        const overwriteDescription = definition.customData.filter(
          (x: any) => x.typeofcontent == "pdescription"
        );

        if (type == "bg") {
          return (
            <GeoWrapper
              key={index}
              list={definition.geo[0]?.listOfCountries}
              country={definition.geo[0]?.countries}
              initial={definition.geo[0]?.countryAvailability}
            >
              <CustomLink
                key={index}
                href={`${routerHandler({
                  typename: definition.product?.__typename,
                  slug: [
                    portfolioSlug,
                    definition.product.productTypeSpecific.slug,
                    definition.product?.slug,
                  ],
                  locale: locale,
                })}`}
                className="flex"
              >
                <div
                  key={definition.id}
                  className={`w-full flex container m-auto   ${
                    isLeft
                      ? "flex-col lg:flex-row-reverse"
                      : "flex-col lg:flex-row"
                  }
              ${!typeOfNavigation ? "scroll-mt-32" : ""}`}
                  id={`${!typeOfNavigation ? definition.product?.slug : ""}`}
                >
                  <div
                    className={`${
                      column == "half"
                        ? "lg:w-1/2"
                        : column == "default"
                        ? "lg:w-4/6"
                        : "lg:w-2/5"
                    }  w-full  flex flex-col gap-4  relative   `}
                  >
                    <div className="relative h-full">
                      <Image
                        data={featureImage}
                        className={` h-full ${
                          featuredSize == "ratio" ? `${styles.ratio}` : ""
                        }   max-h-full `}
                      />
                      <div
                        className={` absolute top-0 z-10 flex  ${
                          reduce ? "max-w-[50%] " : "max-w-[65%] "
                        }${
                          isLeft
                            ? `translate-x-[-10%]  lg:translate-x-[-50%]  left-0 ${
                                reduce
                                  ? "top-[50%] -translate-y-[50%]"
                                  : " translate-y-[-17%]"
                              }`
                            : `translate-x-[10%] lg:translate-x-[50%]  right-0 ${
                                reduce
                                  ? "top-[50%] -translate-y-[50%]"
                                  : "translate-y-[-17%]"
                              }`
                        }`}
                      >
                        <Image
                          data={
                            definition?.product?.factSheetImage ||
                            definition.product?.sku[0]?.skuImageGallery?.[0]
                          }
                        />
                        {parallax[0] && (
                          <LazyMotion features={domAnimation}>
                            <m.div
                              variants={slideUp()}
                              initial="hidden"
                              whileInView="visible"
                              viewport={{ once: true }}
                              exit="exit"
                              className={`hidden lg:flex  absolute  max-w-[65%] right-0 left-0 top-0 bottom-0 m-auto z-[-1]
                           `}
                            >
                              {parallax[1] == "behind" && (
                                <Image
                                  data={parallax[0]}
                                  className="max-h-fit"
                                />
                              )}
                            </m.div>
                          </LazyMotion>
                        )}
                      </div>
                    </div>
                    {parallax[0] && (
                      <LazyMotion features={domAnimation}>
                        {parallax[1] !== "behind" && (
                          <>
                            {" "}
                            <m.div
                              variants={slideUp()}
                              initial="hidden"
                              whileInView="visible"
                              viewport={{ once: true }}
                              exit="exit"
                              className={`   ${
                                isLeft ? "justify-end" : "justify-start"
                              } flex`}
                            >
                              <Image data={parallax[0]} className="max-h-fit" />
                            </m.div>
                          </>
                        )}
                      </LazyMotion>
                    )}
                  </div>
                  <div
                    style={style as React.CSSProperties}
                    className={` ${
                      column == "half"
                        ? "lg:w-1/2"
                        : column == "default"
                        ? "lg:w-2/5"
                        : "lg:w-4/6"
                    }  grid  grid-cols-1  w-full lg:flex flex-col justify-center relative ${
                      styles.bgCard
                    } py-12 `}
                  >
                    {bgImage[0] && (
                      <>
                        {bgImage[2] == "below" && (
                          <div
                            className={`${
                              isLeft
                                ? "lg:translate-x-[10rem]"
                                : "lg:translate-x-[-5rem]"
                            } absolute top-4 bottom-0 m-auto z-[0] opacity-70`}
                          >
                            <Image data={bgImage[1]} />
                          </div>
                        )}
                      </>
                    )}
                    <div
                      className={` flex flex-col gap-24 py-12 lg:py-0 relative z-10 ${
                        isLeft
                          ? "order-2 justify-start lg:items-center  "
                          : " lg:justify-end lg:items-center "
                      }`}
                    >
                      <div
                        className={`${
                          isLeft ? ` pr-0 lg:pr-20` : ` pl-0  lg:pl-20`
                        }  flex flex-col items-start gap-6 xl:gap-12 `}
                      >
                        <div className={`flex flex-col gap-4 items-start`}>
                          <div className="flex flex-col gap-4">
                            <Title
                              tag={"h3"}
                              layout={"left"}
                              data={
                                definition.label ||
                                definition.product.productName
                              }
                              color={text?.color}
                            />
                            {underline?.length > 0 && (
                              <>
                                {underline[1]?.img ? (
                                  <CustomUnderline
                                    shape={underline[1]?.img}
                                    color={underline[2]?.color}
                                  />
                                ) : (
                                  <Underline color={underline[2]?.color} />
                                )}
                              </>
                            )}
                          </div>
                          {showTypeGeneric && (
                            <Paragraph
                              layout={"left"}
                              copy={
                                definition.product.productOrigin
                                  ?.originDesignation
                              }
                              color={text?.color}
                            />
                          )}
                          {description && (
                            <>
                              {overwriteDescription[0]?.content ? (
                                <Paragraph
                                  layout={"left"}
                                  copy={overwriteDescription[0]?.content?.text}
                                  color={text?.color}
                                />
                              ) : (
                                <>
                                  {" "}
                                  <Paragraph
                                    layout={"left"}
                                    copy={
                                      definition.product
                                        ?.productDescriptionShort
                                    }
                                    color={text?.color}
                                  />
                                </>
                              )}
                            </>
                          )}
                        </div>
                        {definition?.showProductAwards &&
                          definition?.product?.awardsAndRatings?.length > 0 && (
                            <div>
                              <ImageLabel
                                copy={t("Ratings")}
                                color={underline[2]?.color}
                                className="pb-4"
                              />
                              <div
                                className={` ${styles.awardList} flex flex-row gap-2`}
                              >
                                {definition?.product?.awardsAndRatings?.map(
                                  (rating: any, index: number) => {
                                    return (
                                      <div>
                                        <Image
                                          key={index}
                                          data={rating?.rating?.ratingImage}
                                          className="max-w-[4rem] max-h-16"
                                        />
                                      </div>
                                    );
                                  }
                                )}
                              </div>

                              <div className="flex gap-4"></div>
                            </div>
                          )}
                        <div>
                          <ButtonMaster
                            anim={"slideLeft"}
                            type={buttonConfig?.buttonStyle}
                            color={buttonConfig?.labelColor?.color}
                            cta={buttonConfig?.typeColor?.color}
                            customIcon={buttonConfig?.icon?.img}
                            customColor={buttonConfig?.iconColor?.color}
                            link={`${routerHandler({
                              typename: definition.product?.__typename,
                              slug: [
                                portfolioSlug,
                                definition.product.productTypeSpecific.slug,
                                definition.product?.slug,
                              ],
                              locale: locale,
                            })}`}
                          >
                            {" "}
                            {buttonConfig?.buttonLabel?.label}
                          </ButtonMaster>
                        </div>
                      </div>
                    </div>
                    <div
                      className={` ${styles.highlightImage} hidden col-span-1 `}
                    >
                      <Image
                        data={
                          definition?.product?.factSheetImage ||
                          definition.product?.sku[0]?.skuImageGallery?.[0]
                        }
                        className="-top-[5rem] sm:-top-28"
                      />
                    </div>
                  </div>
                </div>
              </CustomLink>
            </GeoWrapper>
          );
        } else if (type == "uneven") {
          return (
            <GeoWrapper
              key={index}
              list={definition.geo[0]?.listOfCountries}
              country={definition.geo[0]?.countries}
              initial={definition.geo[0]?.countryAvailability}
            >
              <CustomLink
                key={index}
                href={`${routerHandler({
                  typename: definition.product?.__typename,
                  slug: [
                    portfolioSlug,
                    definition.product.productTypeSpecific.slug,
                    definition.product?.slug,
                  ],
                  locale: locale,
                })}`}
                className="flex"
              >
                <div
                  key={definition.id}
                  className={`w-full flex container m-auto   ${
                    isLeft
                      ? "flex-col lg:flex-row-reverse"
                      : "flex-col lg:flex-row"
                  }
          ${!typeOfNavigation ? "scroll-mt-32" : ""}`}
                  id={`${!typeOfNavigation ? definition.product?.slug : ""}`}
                >
                  <div
                    className={`${
                      column == "half"
                        ? "lg:w-1/2"
                        : column == "default"
                        ? "lg:w-4/6"
                        : "lg:w-2/5"
                    }  w-full  flex flex-col gap-4  relative   `}
                  >
                    <div className="relative h-full">
                      <Image
                        data={featureImage}
                        className={` h-full ${
                          featuredSize == "ratio" ? `${styles.ratio}` : ""
                        }   max-h-fit `}
                      />
                      <div
                        className={` absolute top-0 z-10 flex  ${
                          reduce
                            ? "max-w-[50%] "
                            : "max-w-[55%] lg:max-w-[70%] "
                        }${
                          isLeft
                            ? `translate-x-[-10%]  lg:translate-x-[-50%]  left-0 ${
                                reduce
                                  ? "top-[50%] -translate-y-[50%]"
                                  : " translate-y-[-10%]"
                              }`
                            : `translate-x-[10%] lg:translate-x-[50%]  right-0 ${
                                reduce
                                  ? "top-[50%] -translate-y-[50%]"
                                  : "translate-y-[-10%]"
                              }`
                        }`}
                      >
                        <Image
                          data={
                            definition?.product?.factSheetImage ||
                            definition.product?.sku[0]?.skuImageGallery?.[0]
                          }
                        />
                        {parallax[0] && (
                          <LazyMotion features={domAnimation}>
                            <m.div
                              variants={slideUp()}
                              initial="hidden"
                              whileInView="visible"
                              viewport={{ once: true }}
                              exit="exit"
                              className={`hidden lg:flex  absolute  max-w-[65%] right-0 left-0 top-0 bottom-0 m-auto z-[-1]
                       `}
                            >
                              {parallax[1] == "behind" && (
                                <Image
                                  data={parallax[0]}
                                  className="max-h-fit"
                                />
                              )}
                            </m.div>
                          </LazyMotion>
                        )}
                      </div>
                    </div>
                    {parallax[0] && (
                      <LazyMotion features={domAnimation}>
                        {parallax[1] !== "behind" && (
                          <>
                            {" "}
                            <m.div
                              variants={slideUp()}
                              initial="hidden"
                              whileInView="visible"
                              viewport={{ once: true }}
                              exit="exit"
                              className={`   ${
                                isLeft ? "justify-end" : "justify-start"
                              } flex`}
                            >
                              <Image data={parallax[0]} className="max-h-fit" />
                            </m.div>
                          </>
                        )}
                      </LazyMotion>
                    )}
                  </div>
                  <div
                    style={style as React.CSSProperties}
                    className={` ${
                      column == "half"
                        ? "lg:w-1/2"
                        : column == "default"
                        ? "lg:w-2/5"
                        : "lg:w-4/6"
                    }  grid  grid-cols-1 w-full lg:flex flex-col justify-center relative lg:translate-y-[2rem] ${
                      styles.bgCard
                    } py-12 `}
                  >
                    {bgImage[0] && (
                      <>
                        {bgImage[2] == "below" && (
                          <div
                            className={`${
                              isLeft
                                ? "lg:translate-x-[10rem]"
                                : "lg:translate-x-[-5rem]"
                            } absolute top-4 bottom-0 m-auto z-[0] opacity-70`}
                          >
                            <Image data={bgImage[1]} />
                          </div>
                        )}
                      </>
                    )}
                    <div
                      className={` flex flex-col gap-24 py-12 lg:py-0 relative z-10 ${
                        isLeft
                          ? "order-2 justify-start lg:items-center  "
                          : " lg:justify-end lg:items-center "
                      }`}
                    >
                      <div
                        className={`${
                          isLeft ? ` pr-0 lg:pr-20` : ` pl-0  lg:pl-20`
                        }  flex flex-col items-start gap-6 xl:gap-12 `}
                      >
                        <div className={`flex flex-col gap-4 items-start`}>
                          <div className="flex flex-col gap-4">
                            <Title
                              tag={"h3"}
                              layout={"left"}
                              data={
                                definition.label ||
                                definition.product.productName
                              }
                              color={text?.color}
                            />
                            {underline?.length > 0 && (
                              <>
                                {underline[1]?.img ? (
                                  <CustomUnderline
                                    shape={underline[1]?.img}
                                    color={underline[2]?.color}
                                  />
                                ) : (
                                  <Underline color={underline[2]?.color} />
                                )}
                              </>
                            )}
                          </div>
                          {showTypeGeneric && (
                            <Paragraph
                              layout={"left"}
                              copy={
                                definition.product.productOrigin
                                  ?.originDesignation
                              }
                              color={text?.color}
                            />
                          )}
                          {description && (
                            <>
                              {overwriteDescription[0]?.content ? (
                                <Paragraph
                                  layout={"left"}
                                  copy={overwriteDescription[0]?.content?.text}
                                  color={text?.color}
                                />
                              ) : (
                                <>
                                  {" "}
                                  <Paragraph
                                    layout={"left"}
                                    copy={
                                      definition.product
                                        ?.productDescriptionShort
                                    }
                                    color={text?.color}
                                  />
                                </>
                              )}
                            </>
                          )}
                        </div>
                        {definition?.showProductAwards &&
                          definition?.product?.awardsAndRatings?.length > 0 && (
                            <div>
                              <ImageLabel
                                copy={t("Ratings")}
                                color={underline[2]?.color}
                                className="pb-4"
                              />
                              <div
                                className={` ${styles.awardList} flex flex-row gap-2`}
                              >
                                {definition?.product?.awardsAndRatings?.map(
                                  (rating: any, index: number) => {
                                    return (
                                      <div>
                                        <Image
                                          key={index}
                                          data={rating?.rating?.ratingImage}
                                          className="max-w-[4rem] max-h-16"
                                        />
                                      </div>
                                    );
                                  }
                                )}
                              </div>

                              <div className="flex gap-4"></div>
                            </div>
                          )}
                        <div>
                          <ButtonMaster
                            anim={"slideLeft"}
                            type={buttonConfig?.buttonStyle}
                            color={buttonConfig?.labelColor?.color}
                            cta={buttonConfig?.typeColor?.color}
                            customIcon={buttonConfig?.icon?.img}
                            customColor={buttonConfig?.iconColor?.color}
                            link={`${routerHandler({
                              typename: definition.product?.__typename,
                              slug: [
                                portfolioSlug,
                                definition.product.productTypeSpecific.slug,
                                definition.product?.slug,
                              ],
                              locale: locale,
                            })}`}
                          >
                            {" "}
                            {buttonConfig?.buttonLabel?.label}
                          </ButtonMaster>
                        </div>
                      </div>
                    </div>
                    <div
                      className={` ${styles.highlightImage} hidden col-span-1 `}
                    >
                      <Image
                        data={
                          definition?.product?.factSheetImage ||
                          definition.product?.sku[0]?.skuImageGallery?.[0]
                        }
                        className="-top-[5rem] sm:-top-28"
                      />
                    </div>
                  </div>
                </div>
              </CustomLink>
            </GeoWrapper>
          );
        } else {
          return (
            <GeoWrapper
              key={index}
              list={definition.geo[0]?.listOfCountries}
              country={definition.geo[0]?.countries}
              initial={definition.geo[0]?.countryAvailability}
            >
              <CustomLink
                key={index}
                href={`${routerHandler({
                  typename: definition.product?.__typename,
                  slug: [
                    portfolioSlug,
                    definition.product.productTypeSpecific.slug,
                    definition.product?.slug,
                  ],
                  locale: locale,
                })}`}
                className={`flex relative ${bgElement ? `bg-white` : ``}`}
              >
                {bgElement && (
                  <FullBackgroundImage position="cover" data={bgElement} />
                )}

                <div
                  key={definition.id}
                  className={`w-full flex ${
                    type == "high" ? `px-12 lg:px-32 py-12 lg:py-24` : ``
                  } container mx-auto max-w-[1360px]  ${
                    isLeft
                      ? "flex-col lg:flex-row-reverse"
                      : "flex-col lg:flex-row"
                  }
                ${!typeOfNavigation ? "scroll-mt-32" : ""}`}
                  id={`${!typeOfNavigation ? definition.product?.slug : ""}`}
                >
                  <div
                    className={`${
                      column == "half"
                        ? "lg:w-1/2"
                        : column == "default"
                        ? "lg:w-4/6"
                        : "lg:w-2/5"
                    }  w-full  flex flex-col gap-4 relative  `}
                  >
                    <div className="relative h-full">
                      <Image
                        data={featureImage}
                        className={` h-full ${
                          featuredSize == "ratio" ? `${styles.ratio}` : ""
                        }   max-h-fit`}
                      />
                      <SkuImageContainer
                        align={isLeft}
                        reduce={reduce}
                        type={type}
                        style={style}
                        invertProduct={productImageOrder}
                      >
                        <Image
                          data={
                            definition?.product?.factSheetImage ||
                            definition.product?.sku[0]?.skuImageGallery?.[0]
                          }
                        />
                        {parallax[0] && (
                          <LazyMotion features={domAnimation}>
                            <m.div
                              variants={slideUp()}
                              initial="hidden"
                              whileInView="visible"
                              viewport={{ once: true }}
                              exit="exit"
                              className={`hidden lg:flex  absolute  max-w-[65%] right-0 left-0 top-0 bottom-0 m-auto z-[-1]
     `}
                            >
                              {parallax[1] == "behind" && (
                                <Image
                                  data={parallax[0]}
                                  className="max-h-fit"
                                />
                              )}
                            </m.div>
                          </LazyMotion>
                        )}
                      </SkuImageContainer>
                    </div>
                    {parallax[0] && (
                      <LazyMotion features={domAnimation}>
                        {parallax[1] !== "behind" && (
                          <>
                            {" "}
                            <m.div
                              variants={slideUp()}
                              initial="hidden"
                              whileInView="visible"
                              viewport={{ once: true }}
                              exit="exit"
                              className={`   ${
                                isLeft ? "justify-end" : "justify-start"
                              } flex`}
                            >
                              <Image data={parallax[0]} className="max-h-fit" />
                            </m.div>
                          </>
                        )}
                      </LazyMotion>
                    )}
                  </div>
                  <div
                    style={style as React.CSSProperties}
                    className={` ${
                      column == "half"
                        ? "lg:w-1/2"
                        : column == "default"
                        ? "lg:w-2/5"
                        : "lg:w-4/6"
                    }  grid grid-cols-2 w-full lg:flex flex-col justify-center relative ${
                      type == "bg" ? `${styles.bgCard} py-12` : ""
                    } `}
                  >
                    {bgImage[0] && (
                      <>
                        {bgImage[2] == "below" && (
                          <div
                            className={`${
                              isLeft
                                ? "lg:translate-x-[30%]"
                                : "lg:translate-x-[-30%]"
                            } absolute top-4 bottom-0 m-auto z-[0] opacity-70`}
                          >
                            <Image data={bgImage[1]} />
                          </div>
                        )}
                      </>
                    )}
                    <div
                      className={` flex flex-col gap-24 py-12 lg:py-0 relative z-10 ${
                        isLeft
                          ? "order-2 justify-start lg:items-center md:items-end "
                          : " lg:justify-end lg:items-center "
                      }`}
                    >
                      <div
                        className={`${
                          isLeft ? " lg:pr-[10rem]" : " lg:pl-[10rem]"
                        }  flex flex-col items-start gap-6 ${
                          type === "bg"
                            ? "xl:gap-12"
                            : type == "high"
                            ? ""
                            : "xl:gap-24"
                        } `}
                      >
                        <div className={`flex flex-col gap-4 items-start`}>
                          <div className="flex flex-col gap-4">
                            {type == "high" ? (
                              <BolderTitle
                                tag={"h3"}
                                layout={"left"}
                                data={
                                  definition.label ||
                                  definition.product.productName
                                }
                                color={text?.color}
                              />
                            ) : (
                              <Title
                                tag={"h3"}
                                layout={"left"}
                                data={
                                  definition.label ||
                                  definition.product.productName
                                }
                                color={text?.color}
                              />
                            )}

                            {underline[0] && (
                              <>
                                {underline[1]?.img ? (
                                  <CustomUnderline
                                    shape={underline[1]?.img}
                                    color={underline[2]?.color}
                                  />
                                ) : (
                                  <Underline color={underline[2]?.color} />
                                )}
                              </>
                            )}
                          </div>
                          {description && (
                            <>
                              {overwriteDescription[0]?.content ? (
                                <Paragraph
                                  layout={"left"}
                                  copy={overwriteDescription[0]?.content?.text}
                                  color={text?.color}
                                />
                              ) : (
                                <>
                                  {" "}
                                  <Paragraph
                                    layout={"left"}
                                    copy={
                                      definition.product
                                        ?.productDescriptionShort
                                    }
                                    color={text?.color}
                                  />
                                </>
                              )}
                            </>
                          )}
                          {showTypeGeneric && (
                            <Paragraph
                              layout={"left"}
                              copy={
                                definition.product.productOrigin
                                  ?.originDesignation
                              }
                              color={text?.color}
                            />
                          )}
                        </div>
                        {definition?.showProductAwards && (
                          <div>
                            <div className="flex gap-4"></div>
                          </div>
                        )}

                        <div>
                          <ButtonMaster
                            anim={"slideLeft"}
                            type={buttonConfig?.buttonStyle}
                            color={buttonConfig?.labelColor?.color}
                            cta={buttonConfig?.typeColor?.color}
                            customIcon={buttonConfig?.icon?.img}
                            customColor={buttonConfig?.iconColor?.color}
                            link={`${routerHandler({
                              typename: definition.product?.__typename,
                              slug: [
                                portfolioSlug,
                                definition.product.productTypeSpecific.slug,
                                definition.product?.slug,
                              ],
                              locale: locale,
                            })}`}
                          >
                            {" "}
                            {buttonConfig?.buttonLabel?.label}
                          </ButtonMaster>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`${styles.highlightImage} flex col-span-1 lg:hidden relative`}
                    >
                      <div
                        style={style}
                        className={styles.highlightMobile}
                      ></div>
                      <Image
                        data={
                          definition?.product?.factSheetImage ||
                          definition.product?.sku[0]?.skuImageGallery?.[0]
                        }
                        className="-top-[5rem] sm:-top-28"
                      />
                    </div>
                  </div>
                </div>
              </CustomLink>
            </GeoWrapper>
          );
        }
      })}
    </>
  );
}

interface Grid {
  productsDefinition: any;
  portfolioSlug: any;
  locale: any;
  parallax: any;
  text: any;
  spacer: any;
  bgColor: any;
  buttonConfig: any;
}

export function GridList({
  productsDefinition,
  portfolioSlug,
  locale,
  text,
  spacer,
  bgColor,
  buttonConfig,
}: Grid) {
  return (
    <>
      {spacer[0] && (
        <Shape
          shape={spacer[1]?.customShape}
          color={bgColor}
          direction={spacer[1]?.spacerDirection}
          icon={[spacer[1]?.addIconElement, spacer[1]?.spacerIcon]}
          position={spacer[1]?.spacerPosition}
          invert={false}
        />
      )}
      <div className="container mx-auto px-6 py-24">
        <div className="max-w-[1360px] grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 m-auto gap-24">
          {productsDefinition?.map((definition: any, index: number) => (
            <CustomLink
              className="group"
              href={`${routerHandler({
                typename: definition.product?.__typename,
                slug: [
                  portfolioSlug,
                  definition.product.productTypeSpecific.slug,
                  definition.product?.slug,
                ],
                locale: locale,
              })}`}
            >
              <div className=" col-span-1">
                <div className="flex flex-row  justify-center">
                  <div
                    className={` flex flex-col lg:items-center justify-end  `}
                  >
                    <div className="flex flex-col gap-2 items-start pb-6 relative">
                      <Paragraph
                        copy={definition.product.productBrand.title}
                        color={text?.color}
                      />
                      <span
                        style={{ color: text?.color.hex }}
                        className="font-primary text-base"
                      >
                        {definition.product.vintageYear}
                      </span>
                      <div className="absolute bottom-0 min-w-[10rem] translate-y-[100%] ">
                        <ButtonMaster
                          anim={"slideLeft"}
                          type={buttonConfig?.buttonStyle}
                          color={buttonConfig?.labelColor?.color}
                          cta={buttonConfig?.typeColor?.color}
                          customIcon={buttonConfig?.icon?.img}
                          customColor={buttonConfig?.iconColor?.color}
                          link={`${routerHandler({
                            typename: definition.product?.__typename,
                            slug: [
                              portfolioSlug,
                              definition.product.productTypeSpecific.slug,
                              definition.product?.slug,
                            ],
                            locale: locale,
                          })}`}
                        >
                          {" "}
                          {buttonConfig?.buttonLabel?.label}
                        </ButtonMaster>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`flex  group-hover:scale-110 duration-300 transition-all `}
                  >
                    <Image
                      data={
                        definition?.product?.factSheetImage ||
                        definition.product?.sku[0]?.skuImageGallery?.[0]
                      }
                      className="max-w-[15rem]"
                    />
                  </div>
                </div>
              </div>
            </CustomLink>
          ))}
        </div>
      </div>
    </>
  );
}

interface SkuImageContainer {
  children: any;
  reduce: boolean;
  align: boolean;
  invertProduct: boolean;
  type: string | undefined;
  style: any;
}

export function SkuImageContainer({ ...props }: SkuImageContainer) {
  switch (props.type) {
    case "bg":
      return (
        <>
          {" "}
          {props.align ? (
            <div
              className={`hidden lg:flex  absolute top-0 z-10 ${
                props.reduce
                  ? "max-w-[45%] top-[50%] -translate-y-[50%] "
                  : "max-w-[65%]  translate-y-[-17%]"
              } translate-x-[-50%]  left-0`}
            >
              {props.children}
            </div>
          ) : (
            <div
              className={`hidden lg:flex  absolute top-0 z-10 ${
                props.reduce
                  ? "max-w-[45%] top-[50%] -translate-y-[50%] "
                  : "max-w-[65%]  translate-y-[-17%]"
              } translate-x-[50%]  right-0`}
            >
              {props.children}
            </div>
          )}
        </>
      );
      break;
    case "high":
      return (
        <>
          {" "}
          {props.align ? (
            <div
              className={`hidden lg:flex  absolute top-0 z-10 h-full   ${
                props.reduce
                  ? "max-w-[45%] top-[50%] -translate-y-[30%] "
                  : "max-w-[65%]  translate-y-[-17%]"
              } ${
                props.invertProduct
                  ? "translate-x-[-50%]"
                  : "translate-x-[50%] right-0"
              } top-0 bottom-0 m-auto `}
            >
              <div style={props.style} className={styles.highlight}></div>
              {props.children}
            </div>
          ) : (
            <div
              className={`hidden lg:flex  absolute top-0 z-10 h-full ${
                props.reduce
                  ? "max-w-[45%] top-[50%] -translate-y-[30%] "
                  : "max-w-[65%]  translate-y-[-17%]"
              } ${
                props.invertProduct
                  ? "translate-x-[50%] right-0"
                  : "translate-x-[-50%]"
              } top-0 bottom-0 m-auto `}
            >
              {props.children}
              <div style={props.style} className={styles.highlight}></div>
            </div>
          )}
        </>
      );
      break;
    default:
      return (
        <>
          {" "}
          {props.align ? (
            <div
              className={`hidden lg:flex  absolute top-0 z-10 ${
                props.reduce
                  ? "max-w-[45%] translate-y-[-5%] "
                  : "max-w-[65%]  translate-y-[-17%]"
              } translate-x-[-50%]  left-0 top-0 bottom-0 m-auto `}
            >
              {props.children}
            </div>
          ) : (
            <div
              className={`hidden lg:flex  absolute top-0 z-10 ${
                props.reduce
                  ? "max-w-[45%] translate-y-[-5%] "
                  : "max-w-[65%]  translate-y-[-17%]"
              } translate-x-[50%]  right-0 top-0 bottom-0 m-auto `}
            >
              {props.children}
            </div>
          )}
        </>
      );
      break;
  }
}
