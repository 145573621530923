import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

import styles from "./index.module.scss";

interface Props {
  copy: any;
  color: any[any];
  className?: string;
  layout?: string;
  column?: string;
  card?: string;
  mcolor?: any;
  altColor?: any;
}

export function FeaturedParagraph({ copy, color, layout }: Props) {
  const style = { "--color": color?.hex } as React.CSSProperties;

  return (
    <>
      <p
        style={style as React.CSSProperties}
        className={` ${
          styles.paragraph
        } brandParagraphFeatured text-base lg:text-lg flex flex-col gap-4 ${
          copy?.length > 500 && layout !== "half" ? "lg:columns-2 gap-12" : ""
        }`}
      >
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          components={{
            p: ({ node, ...props }) => <span {...props} />,
            div: React.Fragment,
          }}
        >
          {copy}
        </ReactMarkdown>
      </p>
    </>
  );
}

export function FeaturedWarning({ copy, color, layout }: Props) {
  const style = { "--color": color?.hex } as React.CSSProperties;

  return (
    <>
      <p
        style={style as React.CSSProperties}
        className={` ${styles.paragraph} FeaturedWarning uppercase font-extrabold text-center flex flex-col gap-4`}
      >
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          components={{
            p: ({ node, ...props }) => <span {...props} />,
            div: React.Fragment,
          }}
        >
          {copy}
        </ReactMarkdown>
      </p>
    </>
  );
}

export function Paragraph({
  copy,
  color,
  layout,
  card,
  className,
  mcolor,
  altColor,
}: Props) {
  const style = {
    "--color": color?.hex,
    "--altColor": altColor?.hex,
  } as React.CSSProperties;
  return (
    <div
      style={style as React.CSSProperties}
      className={`  ${
        mcolor?.hex ? `${styles.paragraphVariant} ` : `${styles.paragraph}`
      }  text-sm lg:text-base flex flex-col   brandParagraph ${
        layout === "left"
          ? `${card == "featured" ? "text-center lg:text-left" : "text-left"}`
          : layout === "right"
          ? `${card == "featured" ? "text-center lg:text-left" : "text-left"}`
          : layout === "center"
          ? "lg:text-center"
          : layout === "fixedCenter"
          ? "text-center"
          : ""
      } ${className}`}
    >
      <ReactMarkdown
        rehypePlugins={[rehypeRaw]}
        components={{
          p: ({ node, ...props }) => <p {...props} />,
          ul: ({ node, ...props }) => (
            <ul className="flex flex-col gap-2 lg:gap-4" {...props} />
          ),
          h3: ({ node, ...props }) => (
            <p
              className={`${styles.altColor} text-base lg:text-lg brandTitle leading-tight `}
              {...props}
            />
          ),
          div: React.Fragment,
        }}
      >
        {copy}
      </ReactMarkdown>
    </div>
  );
}

export function ParagraphNumber({
  copy,
  color,
  layout,
  card,
  className,
}: Props) {
  const style = { "--color": color?.hex } as React.CSSProperties;

  return (
    <p
      style={style as React.CSSProperties}
      className={`  ${
        styles.paragraph
      }  text-sm lg:text-base flex flex-col gap-4  brandParagraph ${
        layout === "left"
          ? `${card == "featured" ? "text-center lg:text-left" : "text-left"}`
          : layout === "right"
          ? `${card == "featured" ? "text-center lg:text-left" : "text-left"}`
          : layout === "center"
          ? "lg:text-center"
          : layout === "fixedCenter"
          ? "text-center"
          : ""
      } ${className}`}
    >
      {copy}
    </p>
  );
}

export function Label({ copy, color, className }: Props) {
  const style = { "--color": color?.hex } as React.CSSProperties;
  return (
    <>
      <p
        style={style as React.CSSProperties}
        className={` ${styles.paragraph} ${className} brandLabel text-brandLabel lg:text-brandLabel_lg xl:text-brandLabel_xl max-w-full`}
      >
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          components={{
            p: ({ node, ...props }) => <span {...props} />,
            div: React.Fragment,
          }}
        >
          {copy}
        </ReactMarkdown>
      </p>
    </>
  );
}

export function LabelNumber({ copy, color, className }: Props) {
  const style = { "--color": color?.hex } as React.CSSProperties;
  return (
    <>
      <p
        style={style}
        className={` ${styles.paragraph} ${className} text-xs max-w-full  font-bold font-secondary opacity-50`}
      >
        {copy}
      </p>
    </>
  );
}

export function FeaturedLabel({ copy, color, className }: Props) {
  const style = { "--color": color?.hex } as React.CSSProperties;
  return (
    <>
      <p
        style={style as React.CSSProperties}
        className={` ${styles.paragraph} ${className} brandFeaturedCall text-md lg:text-lg max-w-full`}
      >
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          components={{
            p: ({ node, ...props }) => <span {...props} />,
            div: React.Fragment,
          }}
        >
          {copy}
        </ReactMarkdown>
      </p>
    </>
  );
}

export function HeroLabel({ copy, color, className, layout }: Props) {
  const style = { "--color": color?.hex } as React.CSSProperties;
  return (
    <>
      <p
        style={style as React.CSSProperties}
        className={`${layout == "center" ? "text-center" : "text-left"} ${
          styles.paragraph
        } ${className}   brandHeroLabel text-heroLabelSm lg:text-heroLabel max-w-full  `}
      >
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          components={{
            p: ({ node, ...props }) => <span {...props} />,
            div: React.Fragment,
          }}
        >
          {copy}
        </ReactMarkdown>
      </p>
    </>
  );
}

export function ImageLabel({ copy, color, className }: Props) {
  const style = { "--color": color?.hex } as React.CSSProperties;
  return (
    <>
      <p
        style={style as React.CSSProperties}
        className={` ${styles.paragraph} ${className} brandImageLabel text-xs max-w-full pt-1 flex flex-col gap-2 `}
      >
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          components={{
            p: ({ node, ...props }) => <span {...props} />,
            div: React.Fragment,
            a: ({ node, ...props }) => (
              <a className="underline font-bold" target="_blank" {...props} />
            ),
          }}
        >
          {copy}
        </ReactMarkdown>
      </p>
    </>
  );
}

export function BigNumber({ copy, color, className, layout }: Props) {
  const style = { "--color": color?.hex } as React.CSSProperties;
  return (
    <p
      style={style as React.CSSProperties}
      className={` ${styles.paragraph} ${className} text-center   text-heroLabelSm lg:text-heroLabel max-w-full  brandTitle `}
    >
      <ReactMarkdown
        rehypePlugins={[rehypeRaw]}
        components={{
          p: ({ node, ...props }) => <span {...props} />,
          div: React.Fragment,
        }}
      >
        {copy}
      </ReactMarkdown>
    </p>
  );
}
